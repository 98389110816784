<template>
  <full-page>
    <div slot="sections">
      <vue-language></vue-language>
      <section class="fullpage-center" ref="sections">
        <mobile-slider v-if="mobileDetect()"></mobile-slider>
        <vslider v-else></vslider>
      </section>
      <section class="fullpage-right" ref="sections">
        <modir-amel></modir-amel>
      </section>
      <section class="fullpage-center" ref="sections">
        <about border="border-main">
          <p class="text" slot="text" ref="textAbout"></p>
        </about>
      </section>
       <section class="fullpage-center" ref="sections">
        <gmap></gmap>
      </section>
      <section class="fullpage-center" ref="sections">
        <Footer />
      </section>
    </div>
  </full-page>
</template>

<script>
import VSlider from '@/components/VSlider';
import FullPage from '@/components/FullPage';
import ModirAmel from '@/components/ModirAmel';
import About from '@/components/About';
import Footer from '../components/Footer2.vue'
import httpClient from '@/services/http.service';
import Gmap from '@/components/Gmap';
import Language from '@/components/Language';
import MobileSlider from '@/components/MobileSlider'; 
export default {
  name:'He',
  components: {
    'vslider': VSlider,
    FullPage,
    ModirAmel,
    About,
    Gmap,
    Footer,
    'vue-language' : Language,
    MobileSlider
  },
  data(){
    return {
      categories : new Array(),
    };
  },
  methods: {
    async ho(value) {
       //setInterval(this.$refs.slider.indexing(value), 5000);
      //this.$refs.slider.indexing(value);
      await setTimeout(this.how(value), 2000);
    },
    async how(val){
      await this.$refs.slider.indexing(val);
    },
    async bindData(){
      const {status,data} = await httpClient.get('fa/categories');
      if (status == 200) {
        this.categories = data;
      }else{
        alert('not connect to api');
      }
    },
    mobileDetect(){
      var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
      return isMobile;
    }
  },
  async created() {
    await this.bindData();
    await this.$refs.slider.dataSync();
    console.log(this.categories);
  },
  mounted(){
    this.$refs.textAbout.innerHTML = this.$t('about.message');
  }
}
</script>

<style lang="css" scoped>
</style>
