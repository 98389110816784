<template>
  <div class="slider w-100 h-100">
    <div class="content w-100 h-100">
      <div class="slogon d-flex justify-content-center align-items-center">
        <div class="col-6 o-hidden">
          <transition name="fade">
            <img :src="'/img/logos/'+$route.params.language+'/'+imgSrc" v-if="show" class="slogon-text" alt="">
          </transition>
        </div>
      </div>
      <div class="video-player">
        <video  muted loop id="myVideo" ref="video">
          <source :src="'/video/'+$route.params.language+'/'+videoSrc" type="video/mp4">
        </video>
      </div>
      <div class="d-flex justify-content-center h-100 b-black">
        <div class="categories col-4 bordered w-100 h-100">
          <div class="thumbnail w-100 h-100">
            <div class="thumbnail-shadow"></div>
            <img class="thumbnail-img" id="thumbnail-2" src="/img/1.jpg" alt="">
          </div>
          <div class="category-description mx-auto">
            <a :href="'/'+$route.params.language+'/tajhiz'"><h1 class="category-name tajhiz" @mouseover="hover('logo web-03.png')" @mouseout="unhover()">{{ $t('titles.tajhiz') }}</h1></a>
          </div>
        </div>
        <div class="categories col-4 bordered w-100 h-100">
          <div class="thumbnail w-100 h-100">
            <div class="thumbnail-shadow"></div>
            <img class="thumbnail-img" id="thumbnail-2" src="/img/1-1.jpg" alt="">
          </div>
          <div class="category-description mx-auto">
            <router-link :to="{ name: 'Category', params: {language:$route.params.language,category:'plast'} } " routerLinkActive="active"><h1 class="category-name plast" @mouseover="hover('logo web-02.png','plast')" @mouseout="unhover()">{{ $t('titles.plast') }}</h1></router-link>
          </div>
        </div>
        <div class="categories col-4 bordered w-100 h-100">
          <div class="thumbnail w-100 h-100">
            <div class="thumbnail-shadow"></div>
            <img class="thumbnail-img" id="thumbnail-3" src="/img/3.jpg" alt="">
          </div>
          <div class="category-description mx-auto">
            <router-link :to="{ name: 'Category', params: {language:$route.params.language,category:'building'} } " routerLinkActive="active"><h1 class="category-name building" @mouseover="hover('logo web-02.png','building')" @mouseout="unhover()">{{ $t('titles.building') }}</h1></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'VSlider',
  data(){
    return {
      videoActive: false,
      videoSrc:'',
      videoTajhiz:'tajhiz.mp4',
      videoPlast:'plast.mp4',
      videoBuilding:'building.mp4',
      imgSrc:'logo web-01.png',
      show:true,
    };
  },
  methods: {
  async hover(img,cat='none') {
      //const targets = this.$refs.flip;
      const video = this.$refs.video;
      if (cat=='plast') {
        this.videoSrc=this.videoPlast;
      }else if(cat == 'building'){
        this.videoSrc=this.videoBuilding;
      } else {
        this.videoSrc = this.videoTajhiz;
      }
      video.load();
      this.show = false;
      if (this.videoActive == false) {
        video.load();
        video.play();
        setTimeout(() => {  this.ho(); }, 500);
        this.show = true;
        await this.slogonHide();
        this.videoActive = true;
        //this.imgSrc = img;
        //this.slogonShow(img);
      }
    },
    ho(){
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 3000,
      }).add({
        targets :'.thumbnail',
        duration:3000,
        //rotateY: 180,
        translateY:-700,
        opacity: 0,
      })
      this.show = true;
      this.videoActive = true;
      //this.slogonShow();
      //this.logoAnimation();
      this.logoRotate();
    },
    unhover(){
      const video = this.$refs.video;
      if (this.videoActive == true) {
        video.pause();
          setTimeout(() => {  this.uho(); }, 500);
      }
    },
    uho(){
      this.imgSrc = 'logo web-01.png';
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
      }).add({
        targets :'.thumbnail',
        duration:2000,
        //rotateY: -180,
        translateY:0,
        opacity: 1,
      });
      this.videoActive = false;
      this.logoUnRotate();
      this.slogonShow();
    },
    slogonShow(){
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
      }).add({
        targets :'.slogon-text',
        duration:2000,
        //rotateY: -180,
        translateX:'-110%',
        opacity: 1,
      });
    },
    async slogonHide(){
      await this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
        complete:function(anim){

        }
      }).add({
        targets :'.slogon-text',
        duration:2000,
        //rotateY: -180,
        translateX:'-110%',
        opacity: 0,
      });
    },

  },
  created() {
    setTimeout(() => {
      this.slogonShow();
    }, 7000);
  },
  mounted() {
    this.slogonShow();
  }
}
</script>

<style lang="css" scoped>
.slider{
  background: #000;
  position: absolute;
  z-index: 100;
}
.slogon{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 400;
}
.slogon-text{
  width: 100%;
  position: relative;
  left: 110%;
}
.logo{
  position: absolute;
  left: 46%;
  top: 50px;
  width: 8%;
  z-index: 400;
}
.logo img{
  width: 100%;
}
.bordered{
  border: 1px solid black;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.thumbnail{
  padding:0;
  margin: 0;
}
.thumbnail-shadow{
  background-color: #03033c;
  opacity: 0.6;
  z-index: 200;
  position: absolute;
  width: 100%;
  height: 100%;
}
.thumbnail-img{
  width: 100%;
  height: 100%;
  /*filter: grayscale(100%);*/
  /*filter:hue-rotate(90deg);*/
}
.categories{
  padding: 0;
}
.category-description{
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 90%;
  right: 5%;
  z-index: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.category-description a:hover{
  text-decoration: none;
}
.category-name{
  font-size: 18px;
  text-align: center;
  font-weight: bolder;
  font-family: 'Vazir';
  padding: 3px;
  padding-right: 2rem;
  padding-left: 2rem;
}
.tajhiz{
  color: #f9b729;
  border: 2px solid #f9b729;
  border-radius: 25px;
}
.plast{
  color: #0c7da6;
  border: 2px solid #0c7da6;
  border-radius: 25px;
}
.building{
  color:#e44135;
  border: 2px solid #e44135;
  border-radius: 25px;
}
.b-black{
  background-color: black;
}

.o-hidden{
  overflow: hidden;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cls-1,.cls-2,.cls-3,.cls-4,.cls-1{fill:none;stroke-miterlimit:10;}
.cls-2{stroke:#f9b729;fill-rule:evenodd;}
.cls-3{stroke:#e44135;}
.cls-4,.cls-1{stroke:#0c7da6;}
</style>
