<template>
<div class= "about-us">
    <div class="d-flex justify-content-center">
        <div class="title" :class="border+'-title '+ color">
            <h3 class="pt-1 pr-3 pl-3">{{$t('about.title')}}</h3>
        </div>
    </div>
    <div class="col-lg-6 col-8 mx-auto mt-3 px-0" :class="border+' '+bn">
      <div class="px-4 py-2">
          <slot name="text" class="d-flex justify-content-center mx-4"></slot>
      </div>
    </div>
</div>
</template>

<script>
    export default {
        name:'About',
        props:{
            border:String,
            bn:String,
        },
        data(){
            return{
                color:null,
            }
        },
        mounted(){
            if (this.$route.params.category == null) {
               this.color = 'color-main';
            }
        }
    }
</script>

<style lang="scss">
.title{
    color: #fff;
    font-size: 23px;
    cursor: pointer;
}
.color-main{
    color: rgba(245,161,0,1);
}
.title h3{
    font-size: 23px;
}
.about-us{
    color: #fff;
}
.text{
    padding: 3em;
    text-align: justify;
    font-size: 18px;
    cursor: default;

}
@media (max-width: 600px){
    .title{
        font-size: 18px;
    }
    .title h3{
        font-size: 18px;
    }
    .text{
        font-size: 9px;
    }
}
</style>
