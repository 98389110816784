<template>
  <div class="slider w-100 h-100">
    <div class="content w-100 h-100">
      <div class="justify-content-center h-100 b-black">
        <div class="categories col-12 w-100" ref="handler" v-on:touchstart="changeVideo('tajhiz')">
          <div class="thumbnail w-100 h-100">
              <div class="video-player w-100 h-100" ref="video-tajhiz-base">
                    <video class="w-100 h-100"  muted loop  ref="video-tajhiz">
                        <source :src="'/video/'+$route.params.language+'/'+videoSrc" type="video/mp4">
                    </video>
                </div>
              <div class="thumbnail-shadow"></div>
                <img class="thumbnail-img" id="thumbnail-1" ref="flip" src="/img/2.png" alt="">
          </div>
          <div class="category-description mx-auto">
              <div class="col-6 o-hidden">
                    <transition name="fade">
                        <a :href="'/'+$route.params.language+'/tajhiz'"><img :src="'/img/logos/'+$route.params.language+'/azarpajooheshTajhiz.png'" v-if="show" class="slogon-text" alt=""></a>
                    </transition>
              </div>
          </div>
        </div>
        <div class="categories col-12 w-100" ref="handler-plast" v-on:touchstart="changeVideo('plast')">
          <div class="thumbnail w-100 h-100">
              <div class="video-player w-100 h-100" ref="video-plast-base">
                    <video class="w-100 h-100" height="1080"  muted loop ref="video-plast">
                        <source :src="'/video/'+$route.params.language+'/'+videoSrc" type="video/mp4">
                    </video>
                </div>
            <div class="thumbnail-shadow"></div>
            <img class="thumbnail-img" id="thumbnail-2" src="/img/1-1.jpg" alt="">
          </div>
          <div class="category-description mx-auto">
              <div class="col-6 o-hidden">
                    <transition name="fade">
                        <a :href="'/'+$route.params.language+'/plast'"><img :src="'/img/logos/'+$route.params.language+'/azarpajooheshPlast.png'" v-if="show" class="slogon-text" alt=""></a>
                    </transition>
              </div>
          </div>
        </div>
        <div class="categories col-12 bordered w-100" v-on:touchstart="changeVideo('building')">
          <div class="thumbnail w-100 h-100">
            <div class="video-player w-100 h-100" ref="video-building-base">
                    <video class="w-100 h-100"  muted loop ref="video-building">
                        <source :src="'/video/'+$route.params.language+'/'+videoSrc" type="video/mp4">
                    </video>
                </div>
            <div class="thumbnail-shadow"></div>
            <img class="thumbnail-img" id="thumbnail-3" src="/img/3.jpg" alt="">
          </div>
          <div class="category-description mx-auto">
            <div class="col-6 o-hidden">
                    <transition name="fade">
                        <a :href="'/'+$route.params.language+'/building'"><img :src="'/img/logos/'+$route.params.language+'/logo web-01.png'" v-if="show" class="slogon-text" alt=""></a>
                    </transition>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'MobileSlider',
  data(){
    return {
      videoActive: false,
      videoActiveClass:'hide-video',
      videoSrc:'',
      videoTajhiz:'tajhiz.mp4',
      videoPlast:'plast.mp4',
      videoBuilding:'building.mp4',
      imgSrc:'logo web-01.png',
      videoCat : 'tajhiz',
      show:true,
    };
  },
  methods: {
    changeVideo(cat){
        let ref = `video-${cat}`;
        let refBasePlast = this.$refs['video-plast-base'];
        let refBaseTajhiz = this.$refs['video-tajhiz-base'];
        let refBaseBuilding = this.$refs['video-building-base'];
        var name, arr;

        this.videoCat = cat; 
        const video = this.$refs[ref];
        if (this.videoActive == true) {
            this.unhover();
            this.videoActiveClass = 'hide-video';
            this.show = true;
            this.videoActive = false;
            refBasePlast.classList.remove("sh-video");
            refBaseTajhiz.classList.remove("sh-video");
            refBaseBuilding.classList.remove("sh-video");
            this.slogonShow();
        }else{
            this.show = false;
            this.videoActive = false;
            name = 'sh-video'
            if (cat=='plast') {
            this.videoSrc=this.videoPlast;
            arr = refBasePlast.className.split(" ");
            if (arr.indexOf(name) == -1) {
              refBasePlast.className += " " + name;
            }
                }else if(cat == 'building'){
                  arr = refBaseBuilding.className.split(" ");
                  if (arr.indexOf(name) == -1) {
                    refBaseBuilding.className += " " + name;
                  }
                  this.videoSrc=this.videoBuilding;
                }else {
                    arr = refBaseTajhiz.className.split(" ");
                    if (arr.indexOf(name) == -1) {
                      refBaseTajhiz.className += " " + name;
                    }
                    this.videoSrc = this.videoTajhiz;
                }
            this.videoActiveClass = 'show-video';
            video.load();
            video.play();
            this.videoActive = true;
        }
        //this.videoActive = true;
    },
    tochStart(e){
        e.preventDefault();
        let ref = `video-${this.videoCat}`; 
        const video = this.$refs[ref];
        video.load();
        this.show = false;
      if (this.videoActive == false) {
        video.load();
        video.play();
        this.videoActive = true;
        //this.imgSrc = img;
        //this.slogonShow(img);
      }
    },
    ho(){
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 3000,
      }).add({
        targets :'.thumbnail',
        duration:3000,
        //rotateY: 180,
        translateY:-700,
        opacity: 0,
      })
      this.show = true;
      this.videoActive = true;
      this.slogonShow();
      //this.logoAnimation();
      this.logoRotate();
    },
    unhover(){
      let ref = `video-${this.videoCat}`; 
        const video = this.$refs[ref];
      if (this.videoActive == true) {
        video.pause();
      }
      this.slogonShow();
    },
    uho(){
      this.imgSrc = 'logo web-01.png';
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
      }).add({
        targets :'.thumbnail',
        duration:2000,
        //rotateY: -180,
        translateY:0,
        opacity: 1,
      });
      this.videoActive = false;
      this.logoUnRotate();
      this.slogonShow();
    },
    slogonShow(){
      this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
      }).add({
        targets :'.slogon-text',
        duration:2000,
        //rotateY: -180,
        translateX:'-110%',
        opacity: 1,
      });
    },
    async slogonHide(){
      await this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 2000,
        complete:function(anim){

        }
      }).add({
        targets :'.slogon-text',
        duration:2000,
        //rotateY: -180,
        translateX:'-110%',
        opacity: 0,
      });
    },

  },
  created() {
    setTimeout(() => {
      this.slogonShow();
    }, 7000);
  },
  mounted() {
    this.slogonShow();
    let videoHandler = this.$refs['handler'];
    //videoHandler.addEventListener('touchstart',this.tochStart());
    //videoHandler.addEventListener('touchstart', this.tochStart, { passive: false }); // mobile devices
  }
}
</script>

<style lang="css" scoped>
.slider{
  background: #000;
  position: absolute;
  z-index: 100;
}
.slogon{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 400;
}
.slogon-text{
  width: 100%;
  position: relative;
  left: 110%;
}
.logo{
  position: absolute;
  left: 46%;
  top: 50px;
  width: 8%;
  z-index: 400;
}
.logo img{
  width: 100%;
}
.bordered{
  border: 1px solid black;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.thumbnail{
  padding:0;
  margin: 0;
}
.thumbnail-shadow{
  background-color: #03033c;
  opacity: 0.6;
  z-index: 200;
  position: absolute;
  width: 100%;
  height: 100%;
}
.thumbnail-img{
  width: 100%;
  height: 100%;
  /*filter: grayscale(100%);*/
  /*filter:hue-rotate(90deg);*/
}
.categories{
  padding: 0;
  height: 33% !important;
}
.category-description{
  position: absolute;
  top: 40%;
  height: 20%;
  width: 90%;
  right: 5%;
  z-index: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.category-description a:hover{
  text-decoration: none;
}
.category-name{
  font-size: 18px;
  text-align: center;
  font-weight: bolder;
  font-family: 'Vazir';
  padding: 3px;
  padding-right: 2rem;
  padding-left: 2rem;
}
.tajhiz{
  color: #f9b729;
  border: 2px solid #f9b729;
  border-radius: 25px;
}
.plast{
  color: #0c7da6;
  border: 2px solid #0c7da6;
  border-radius: 25px;
}
.building{
  color:#e44135;
  border: 2px solid #e44135;
  border-radius: 25px;
}
.b-black{
  background-color: black;
}

.o-hidden{
  overflow: hidden;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.h33{
    height: 33% !important;
}
.video-player{
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;
    width: 100%;
    height: 100%;
}
video {
  width: 100%;
  height: auto;
}
.hide-video{
    display: none;
    opacity: 0;
}
.sh-video{
  opacity: 1;
  display: block;
}

.cls-1,.cls-2,.cls-3,.cls-4,.cls-1{fill:none;stroke-miterlimit:10;}
.cls-2{stroke:#f9b729;fill-rule:evenodd;}
.cls-3{stroke:#e44135;}
.cls-4,.cls-1{stroke:#0c7da6;}
</style>
