<template lang="html">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-10">
        <div :class="'modir-talk modir-talk-'+direction">
            <h1 ref="ceoTitle" class="ceoTitle" :class="direction"></h1>
            <p ref ="ceo" class="ceoContent f-20" v-if="$route.params.language == 'en'"></p>
            <p ref ="ceo"  v-else></p>
        </div>
        <div class="modirsingDiv col-12">
          <div class="row">
          <div class="col-4">
            </div>
            <div class="col-4">
              </div>
            <div class="col-4">
                  <img src="/img/emza.png" class="modirSingImg" />
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nam:'ModirAmel',
  data(){
    return{
      direction:'rtl'
    }
  },
  mounted(){
    let title = this.$refs.ceoTitle;
    let ceo = this.$refs.ceo;
    title.innerHTML = this.$t("ceoTitle");
    ceo.innerHTML = this.$t('ceo');
    if (this.$route.params.language == 'en') {
      this.direction = "ltr";
    }
  }
}
</script>

<style lang="css" scoped>
.modir-talk{
  font-family: 'MjSayeh';
  color: #fff;
}
.modir-talk-rtl h1{
  font-size: 34px;
}
.modir-talk-rtl p{
  font-size: 28px;
}
.modir-talk-ltr h1{
  font-size: 23px;
}
.modir-talk-ltr p{
  font-size: 19px;
}
.modirSingImg{
  width: 70%;
  margin-right: 44%;
}
.f-20{
  font-size: 20px;
}
.rtl{
  direction:rtl;
  text-align: justify;
}
.ltr{
  text-align: left;
}
.ceoTitle{
  font-family: 'Gotham-Medium','MjSayeh';
  line-height: 1.5;
  padding-right: 0;
}
.modir-talk p{
  text-align: justify;
  font-family: 'Gotham-Medium','MjSayeh';
}
@media screen and (max-width: 600px) {
    .modir-talk-rtl h1{
      font-size: 17px;
    }
    .modir-talk-rtl p{
      font-size: 14px;
    }
    .modir-talk-ltr h1{
      font-size: 12px;
    }
    .modir-talk-ltr p{
      font-size: 10px;
    }
    .modirSingImg{
      width: 70%;
      margin-right: 44%;
    }
    .f-20{
      font-size: 10px;
    }
}
</style>
