<template>
    <footer class="footer">
      <div class="col-12">
        <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-6 footer-item justify-content-center mb-5 aligin-item-center">
                <h6 :class="titleClass">
                    {{ $t('footer.units') }}
                </h6>
                <ul :class="listClass">
                    <li> <a :href="'/'+$i18n.locale+'/building'" class="nav-item">{{ $t('titles.building') }}</a></li>
                    <li> <a :href="'/'+$i18n.locale+'/tajhiz'" class="nav-item">{{ $t('titles.tajhiz') }} </a></li>
                    <li> <a :href="'/'+$i18n.locale+'/plast'" class="nav-item">{{ $t('titles.plast') }}</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-6 footer-item justify-content-center mb-5">
                <h6 :class="titleClass">
                   {{ $t('footer.fast')}}
                </h6>
                <ul :class="listClass">
                    <li><a :href="'/'+$i18n.locale" class="nav-item">{{ $t('menu.home') }}</a></li>
                    <li><a :href="'/'+$i18n.locale+'/plast/about'" class="nav-item">{{ $t('menu.about') }}</a></li>
                    <li><a :href="'/'+$i18n.locale+'/plast/products'" class="nav-item">{{ $t('menu.products') }}</a></li>
                    <li><a :href="'/'+$i18n.locale+'/contact'" class="nav-item">{{ $t('menu.contact') }}</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-6 footer-item justify-content-center mb-5">
                <h6 :class="titleClass">
                    {{ $t('footer.addr')}}
                </h6>
                <div class="aligin-right" :class="listClass" v-if="$route.params.language == 'fa'">
                    <span>{{ $t("footer.address") }}</span>
                </div>
                <div class="aligin-left" :class="listClass" v-else>
                    <span>{{ $t("footer.address") }}</span>
                </div>
            </div>
            <div class="col-lg-3 col-6 footer-item justify-content-center mb-5">
                <h6 :class="titleClass">
                    {{ $t('footer.contacts')}}
                </h6>
                <ul :class="listClass">
                    <li class="ltr f-12 font-b">021-88217680<span class="f-11"> {{ $t("footer.tnumberCount") }}</span></li>
                    <li class="ltr f-12 font-b">021-88217680 {{ $t("footer.fax") }}</li>
                    <li class="ltr font-b f-16">info@azarpajoohesh.com</li>
                </ul>
            </div>
        </div>
        </div>
        <div class="d-flex justify-content-center copyright col mt-5 py-3">
            <h6>{{ $t("footer.message") }}</h6>
        </div>
      </div>
    </footer>
</template>

<script>
    export default {
        name:'Footer',
        data(){
            return{
                titleClass : String,
                listClass : String,
            }
        },
        mounted(){
            if (this.$route.params.language == 'fa') {
                this.titleClass = 'footer-title';
                this.listClass = 'footer-list';
            }else{
                this.titleClass = 'footer-title-ltr';
                this.listClass = 'footer-list-ltr';
            }
        }
    }
</script>

<style lang="scss" scoped>
.footer{
    text-align: justify;
    color: #fff;
    width: 100%;
}
.container{
  width: 100%;
}
.footer-title{
    border-bottom: #fff 2px solid;
    padding-bottom:5px ;
    max-width: 45%;
    font-weight: bolder;
}
.footer-title::after{
    content: " ";
    position: absolute;
    display: block;
    width: 10%;
    height: 25px;
    margin-top: -18px;
    margin-right: 36%;
    z-index: 0;

    border-left:2px solid #fff;

    transform-origin: top left;
    -ms-transform: skew(30deg, 0deg);
    -webkit-transform: skew(30deg, 0deg);
    transform: skew(30deg, 0deg);
}
.footer-list{
    border-right: #fff 2px solid;
    margin-top: -8px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 180px;
    height: 100%;
}
.footer-list-ltr{
    border-left: #fff 2px solid;
    margin-top: -8px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 180px;
    height: 100%;
}
.footer-list li a , .footer-list-ltr li a{
    color:#fff;
}
.footer-list li a:hover , .footer-list-ltr li a:hover{
    text-decoration: none;
}
ul.footer-list li::marker , ul.footer-list-ltr li::marker{
    content: '';
    padding:0;

}
.ltr{
    direction: ltr;
}
.f-16{
font-size: 13px;
}
.f-12{
font-size: 14.5px;
}
.f-11{
    font-size: 11px;
}
.copyright{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    bottom: 0;
}
.font-b{
    font-family: 'Gotham-Medium';
    font-weight: 10;
}
.footer-title-ltr{
    border-bottom: #fff 2px solid;
    padding-bottom:5px ;
    max-width: 45%;
    font-weight: bold;
}
.footer-title-ltr::after{
    content: " ";
    position: absolute;
    display: block;
    width: 10%;
    height: 25px;
    margin-top: -18px;
    margin-left: 36%;
    z-index: 0;

    border-right:2px solid #fff;

    transform-origin: top left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
}
@media (max-width:600px){
    h6{
        font-size: 9px;
    }
    .copyright{
        position: fixed;
        bottom: 0;
    }
    li{
        font-size: 8px;
    }
    ul{
        list-style-type:none;
    }
    li::marker{
        content: '';
    }
    span{
        font-size: 8px;
    }
    .f-16{
        font-size: 8px;
    }
    .pr-5{
        padding-right: 2rem !important;
        padding-left: auto !important;
    }
    .footer-title{
        max-width: 43%;
    }
    .footer-list li a{
        color:#fff;
    }
    .footer-list li a:hover{
    text-decoration: none;
    }
    a.nav-item{
        color: #fff;
    }
    a{
        color:#fff;
    }
}
.aligin-right{
    text-align: justify;
}
.aligin-left{
    text-align: justify;
}
</style>
