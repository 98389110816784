<template>
    <div class="language-view">
        <a href="/fa" class="link" v-if="language == 'en'">fa</a>
        <a href="/en" class="link" v-else-if="language == 'fa'">en</a>
    </div>
</template>

<script>
export default {
    name:'Language',
     data(){
         return {
             language : this.$route.params.language,
         }
     }
}
</script>

<style lang="css">
.language-view{
    width: 2.25em;
    height: 3em;
    direction: rtl;
    position: fixed;
    top: 40px;
    left: 30px;
    opacity: 0.7;
    display: block;
    z-index: 200;
    cursor: pointer;
}
.link{
    color: #fff;
    font-size: 25px;
    font-weight: bolder;
    text-decoration: none;
    cursor: pointer;
}
.link:hover{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
</style>